@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
::selection{
    background-color: #3266D0;
    color: white;
    /* font-family: 'Inter', sans-serif; */
}

